import { useState, ReactNode, useEffect } from "react";
import styles from "./VerifyEmail.module.scss";
import VfiInput from "src/assets/VfiInput/VfiInput";
import VfiButton from "src/assets/VfiButton/VfiButton";
import { useRouterStore } from "src/Components/Router/routerStore";
import env from "../../../../../../environment.json";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
interface IVerificationCode {
  value: string;
  error: string;
}

export default function VerifyEmail() {
  const { navByLink, get_parameters } = useRouterStore();

  const { addOverlay } = overlayStore();

  const [resendVerifyProcessing, setResendVerifyProcessing] = useState<boolean>(false);
  const [resendVerifyMessage, setResendVerifyMessage] = useState<ReactNode>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [verification_code, setVerificationCode] = useState<IVerificationCode>({
    value: "",
    error: "",
  });

  const retryProcess = () => {
    setResendVerifyProcessing(true);
    axios
      .post(`${env.protocol}${env.env}/api/public/resendVerificationUser`, { email: get_parameters?.email })
      .then((response) => {
        if (response.data?.data?.id) {
          setResendVerifyMessage(
            <p style={{ color: "#7ecf8f" }}>Verification code resent to: {get_parameters?.email}</p>
          );
        } else {
          setResendVerifyMessage(
            <p style={{ color: "#f64e60" }}>Something went wrong when attempting to resend verification code.</p>
          );
        }
      })
      .catch(() => {
        setResendVerifyMessage(
          <p style={{ color: "#f64e60" }}>Something went wrong when attempting to resend verification code.</p>
        );
      })
      .finally(() => {
        setResendVerifyProcessing(false);
      });
  };

  const verifyEmail = () => {
    if (loading) return;

    setLoading(true);

    axios
      .post(`${env.protocol}${env.env}/api/public/verifyEmail`, {
        email: get_parameters?.email ?? "",
        code: verification_code.value,
      })
      .then((response) => {
        if (response?.data?.data?.success) {
          navByLink(get_parameters?.redirect ?? "login");
        }
      })
      .catch((error) => {
        setVerificationCode({
          ...verification_code,
          error: error.response.data.message,
        });
        addOverlay("side_popout_self_close", (close) => {
          return <div className={styles.popout_error}>{error.response?.data?.message ?? "An error occured"}</div>;
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <div className={`${styles["top-text"]}`}>
        <p>We've sent a verification code to your email</p>
        <p>
          Haven't received email? Click{" "}
          <button className={styles["resend-email-button"]} onClick={retryProcess}>
            here
          </button>{" "}
          to resend.
        </p>
        {resendVerifyProcessing && <FontAwesomeIcon icon={faSpinner as IconProp} spin />}
        {resendVerifyMessage}
      </div>
      <VfiInput
        className={styles.input}
        value={verification_code.value}
        error={verification_code.error}
        placeholder={"Verification code"}
        onChange={(e) => {
          setVerificationCode({
            value: e.target.value,
            error: "",
          });
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") verifyEmail();
        }}
      />

      <VfiButton
        className={styles.verify}
        onClick={() => {
          verifyEmail();
        }}
      >
        {!loading ? (
          "Verify"
        ) : (
          <div className={styles.loading_text}>
            Verifying <FontAwesomeIcon spin icon={faLoader as IconProp} />
          </div>
        )}
      </VfiButton>
    </div>
  );
}
